<template>
    <header>
        <div class="container">
            <img class="logo" @click="toPage('/')" src="@/assets/images/logo1.png" />
            <div class="menu">
                <div @click="onSwitchMenu('introduce')" :class="activeMenu === 'introduce' ? 'active' : ''">
                    课程介绍
                </div>
                <div @click="onSwitchMenu('catalog')" :class="activeMenu === 'catalog' ? 'active' : ''">课程目录</div>
            </div>
            <div @click="onStudy" v-if="isPaidStatus" class="study-btn">立即学习</div>
            <div @click="onBuy" v-else class="buy-btn">立即支付</div>
        </div>
    </header>
</template>
<script>
export default {
    name: 'headerLayout',
    props: ['activeMenu', 'isPaidStatus'],
    data() {
        return {}
    },
    methods: {
        toPage(path) {
            if (this.$route.path !== path) {
                this.$router.push(path)
            }
        },
        onSwitchMenu(menu) {
            this.$emit('change', menu)
        },
        onBuy() {
            this.$emit('buy')
        },
        onStudy() {
            this.$emit('study')
        },
    },
}
</script>
<style lang="scss" scoped>
header {
    height: 64px;
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    box-shadow: 0px 2px 8px 0px rgba(107, 102, 99, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    .container {
        width: $center-width;
        display: flex;
        align-items: center;
        padding: 0 16px;
        box-sizing: border-box;
        position: relative;
        height: 64px;
        .logo {
            height: 42px;
            cursor: pointer;
            background: none;
        }
        .menu {
            display: flex;
            height: 100%;
            margin-left: 60px;
            > div {
                font-size: 16px;
                line-height: 16px;
                letter-spacing: 1px;
                cursor: pointer;
                width: 112px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                color: #33312f;
            }
            .active {
                background: $theme-color;
                font-weight: 700;
                position: relative;
                color: #ffffff;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    width: 28px;
                    height: 2px;
                    background: $theme-color;
                    transform: translateX(-50%);
                }
            }
        }
        .study-btn,
        .buy-btn {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            right: 24px;
            width: 156px;
            height: 40px;
            background: $theme-color;
            border-radius: 4px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            color: #ffffff;
            cursor: pointer;
        }
        .study-btn {
            background: #f08e0e;
        }
    }
}
</style>
