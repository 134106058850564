import { nuwa_post_form, begonia_post_form, nuwa_post } from './http'

export const getRecommend = (data) => {
    return nuwa_post_form({
        path: '/coursePackage/getRecommend',
        data,
    })
}

export const coursePackageList = (packageNos) => {
    return nuwa_post_form({
        path: '/coursePackage/list',
        data: {
            packageNos,
        },
    })
}

export const getPayType = () => {
    return begonia_post_form({
        path: '/trade/getProvider',
    })
}

export const uploadAction = (data) => {
    return nuwa_post({
        path: '/app/action',
        data: data,
        isCatch: true,
    })
}
